.datatable-demo .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
  display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details > .p-grid {
  border-radius: 3px;
  margin: 0.3em;
  text-align: center;
  padding: 2em 0 2.25em 0;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
  margin: 0.25em 0 2em 0;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
  margin-left: 0.5em;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
  margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}
.dataview-demo .p-dataview .car-details > div {
  display: flex;
  align-items: center;
}
.dataview-demo .p-dataview .car-details > div img {
  margin-right: 14px;
}
.dataview-demo .p-dataview .car-detail {
  padding: 0 1em 1em 1em;
  margin: 1em;
}

@media (max-width: 1024px) {
  .dataview-demo .p-dataview .car-details img {
    width: 75px;
  }
}

/* Add your customizations of layout here */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3059c9;
}

body {
  background-color: #F5F5F5;
  color: #787878;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased !important;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  color: #282828;
}

button {
  cursor: pointer;
}

.wrapper {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* Make clicks pass-through */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*Data */
.divCardComp {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: -10px;
  margin-right: -10px;
  line-height: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.divGlobal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  line-height: 1;
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 500 !important;
  color: #212529;
}

body .ui-steps:before {
  top: 41%;
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  width: 140px;
  word-break: break-word;
  white-space: initial;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

body .ui-button.ui-button-text-only .ui-button-text {
  font-family: 'Roboto', sans-serif;
}

.ui-g.form-group .textLabel {
  font-family: 'Roboto', sans-serif;
  display: block;
  margin-bottom: 7px;
}

.ui-g.form-group .ui-g.form-group > div {
  margin-bottom: 20px;
}

body .ui-multiselect .ui-multiselect-label {
  padding: 10px 10px;
  height: 34px;
  font-size: 14px;
}

body .ui-widget-content {
  border: transparent;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
  padding: 10px 20px;
  border-radius: 0;
  font-size: 14px;
  border-bottom: solid 1px #f1f1f1;
}

body .ui-dropdown-panel .ui-dropdown-filter-container .pi {
  top: 20px;
  right: 20px;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: solid 1px #f1f1f1;
}

body .ui-chkbox .ui-chkbox-box {
  width: 15px;
  height: 15px;
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .pi {
  top: 10px;
  left: 5px;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  font-size: 12px;
  line-height: 12px;
}

body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  margin: auto;
}

.cardPadre {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 10px 0 #d8d8d8;
  box-shadow: 0 2px 10px 0 #d8d8d8;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 24px;
  line-height: 1;
}

.cardHijo {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  line-height: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cardHijoIncome {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 50%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1;
  border-right: solid 0.5px #ebebeb;
}

.cardHijoIncomex {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 50%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1;
}

.subMenuPadre {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 3.5%;
  min-width: 0;
  line-height: 1;
  margin-bottom: 27px;
}

.subMenuPadreHijo {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 10px 0 #d8d8d8;
  box-shadow: 0 2px 10px 0 #d8d8d8;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
}

.subMenuPadreNieto {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subMenuPadreNietoCard {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  line-height: 1;
  position: relative;
}

.subMenuPadreNietoCard2 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.suhMenuP {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #cccccc;
}

.subMenuPadreNietoCard2:hover {
  color: #3059c9;
}

.suhMenuPActive {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #3059c9;
}

.p-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5em;
  margin-left: -0.5em;
  margin-top: -0.5em;
}

.p-grid > .p-col,
.p-grid > [class*=p-col] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.p-nogutter > .p-col,
.p-nogutter > [class*=p-col-] {
  padding: 0;
}

.p-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-dir-rev {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.p-dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-dir-col-rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.p-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.p-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.p-justify-even {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.p-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.p-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.p-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 0.5em;
}

.p-col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em;
}

.p-col-align-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.p-col-align-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.p-col-align-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p-col-align-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.p-col-align-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.p-dir-col > .p-col,
.p-dir-col-rev > .p-col {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em;
}

.p-col-1 {
  width: 8.3333%;
}

.p-col-2 {
  width: 16.6667%;
}

.p-col-3 {
  width: 25%;
}

.p-col-4 {
  width: 33.3333%;
}

.p-col-5 {
  width: 41.6667%;
}

.p-col-6 {
  width: 50%;
}

.p-col-7 {
  width: 58.3333%;
}

.p-col-8 {
  width: 66.6667%;
}

.p-col-9 {
  width: 75%;
}

.p-col-10 {
  width: 83.3333%;
}

.p-col-11 {
  width: 91.6667%;
}

.p-col-12 {
  width: 100%;
}

.p-col-order-first {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.p-col-order-last {
  -ms-flex-order: 13;
  -webkit-box-ordinal-group: 14;
  order: 13;
}

.p-col-order-0 {
  -ms-flex-order: 0;
  -webkit-box-ordinal-group: 1;
  order: 0;
}

.p-col-order-1 {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.p-col-order-2 {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  order: 2;
}

.p-col-order-3 {
  -ms-flex-order: 3;
  -webkit-box-ordinal-group: 4;
  order: 3;
}

.p-col-order-4 {
  -ms-flex-order: 4;
  -webkit-box-ordinal-group: 5;
  order: 4;
}

.p-col-order-5 {
  -ms-flex-order: 5;
  -webkit-box-ordinal-group: 6;
  order: 5;
}

.p-col-order-6 {
  -ms-flex-order: 6;
  -webkit-box-ordinal-group: 7;
  order: 6;
}

.p-col-order-7 {
  -ms-flex-order: 7;
  -webkit-box-ordinal-group: 8;
  order: 7;
}

.p-col-order-8 {
  -ms-flex-order: 8;
  -webkit-box-ordinal-group: 9;
  order: 8;
}

.p-col-order-9 {
  -ms-flex-order: 9;
  -webkit-box-ordinal-group: 10;
  order: 9;
}

.p-col-order-10 {
  -ms-flex-order: 10;
  -webkit-box-ordinal-group: 11;
  order: 10;
}

.p-col-order-11 {
  -ms-flex-order: 11;
  -webkit-box-ordinal-group: 12;
  order: 11;
}

.p-col-order-12 {
  -ms-flex-order: 12;
  -webkit-box-ordinal-group: 13;
  order: 12;
}

.p-offset-12 {
  margin-left: 100%;
}

.p-offset-11 {
  margin-left: 91.66666667%;
}

.p-offset-10 {
  margin-left: 83.33333333%;
}

.p-offset-9 {
  margin-left: 75%;
}

.p-offset-8 {
  margin-left: 66.66666667%;
}

.p-offset-7 {
  margin-left: 58.33333333%;
}

.p-offset-6 {
  margin-left: 50%;
}

.p-offset-5 {
  margin-left: 41.66666667%;
}

.p-offset-4 {
  margin-left: 33.33333333%;
}

.p-offset-3 {
  margin-left: 25%;
}

.p-offset-2 {
  margin-left: 16.66666667%;
}

.p-offset-1 {
  margin-left: 8.33333333%;
}

.p-offset-0 {
  margin-left: 0%;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding: 0.5em;
}

.p-col-nogutter {
  padding: 0;
}

@media screen and (min-width: 576px) {
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .p-sm-1 {
    width: 8.3333%;
  }
  .p-sm-2 {
    width: 16.6667%;
  }
  .p-sm-3 {
    width: 25%;
  }
  .p-sm-4 {
    width: 33.3333%;
  }
  .p-sm-5 {
    width: 41.6667%;
  }
  .p-sm-6 {
    width: 50%;
  }
  .p-sm-7 {
    width: 58.3333%;
  }
  .p-sm-8 {
    width: 66.6667%;
  }
  .p-sm-9 {
    width: 75%;
  }
  .p-sm-10 {
    width: 83.3333%;
  }
  .p-sm-11 {
    width: 91.6667%;
  }
  .p-sm-12 {
    width: 100%;
  }
  .p-sm-offset-12 {
    margin-left: 100%;
  }
  .p-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .p-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .p-sm-offset-9 {
    margin-left: 75%;
  }
  .p-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .p-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .p-sm-offset-6 {
    margin-left: 50%;
  }
  .p-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .p-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .p-sm-offset-3 {
    margin-left: 25%;
  }
  .p-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .p-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .p-sm-offset-0 {
    margin-left: 0%;
  }
  .p-sm-order-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .p-sm-order-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .p-sm-order-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .p-sm-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .p-sm-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .p-sm-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .p-sm-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .p-sm-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .p-sm-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .p-sm-order-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .p-sm-order-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .p-sm-order-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .p-sm-order-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .p-sm-order-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .p-sm-order-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
}

@media screen and (min-width: 768px) {
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .p-md-1 {
    width: 8.3333%;
  }
  .p-md-2 {
    width: 16.6667%;
  }
  .p-md-3 {
    width: 25%;
  }
  .p-md-4 {
    width: 33.3333%;
  }
  .p-md-5 {
    width: 41.6667%;
  }
  .p-md-6 {
    width: 50%;
  }
  .p-md-7 {
    width: 58.3333%;
  }
  .p-md-8 {
    width: 66.6667%;
  }
  .p-md-9 {
    width: 75%;
  }
  .p-md-10 {
    width: 83.3333%;
  }
  .p-md-11 {
    width: 91.6667%;
  }
  .p-md-12 {
    width: 100%;
  }
  .p-md-offset-12 {
    margin-left: 100%;
  }
  .p-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .p-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .p-md-offset-9 {
    margin-left: 75%;
  }
  .p-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .p-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .p-md-offset-6 {
    margin-left: 50%;
  }
  .p-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .p-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .p-md-offset-3 {
    margin-left: 25%;
  }
  .p-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .p-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .p-md-offset-0 {
    margin-left: 0%;
  }
  .p-md-order-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .p-md-order-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .p-md-order-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .p-md-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .p-md-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .p-md-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .p-md-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .p-md-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .p-md-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .p-md-order-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .p-md-order-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .p-md-order-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .p-md-order-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .p-md-order-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .p-md-order-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
}

@media screen and (min-width: 992px) {
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .p-lg-1 {
    width: 8.3333%;
  }
  .p-lg-2 {
    width: 16.6667%;
  }
  .p-lg-3 {
    width: 25%;
  }
  .p-lg-4 {
    width: 33.3333%;
  }
  .p-lg-5 {
    width: 41.6667%;
  }
  .p-lg-6 {
    width: 50%;
  }
  .p-lg-7 {
    width: 58.3333%;
  }
  .p-lg-8 {
    width: 66.6667%;
  }
  .p-lg-9 {
    width: 75%;
  }
  .p-lg-10 {
    width: 83.3333%;
  }
  .p-lg-11 {
    width: 91.6667%;
  }
  .p-lg-12 {
    width: 100%;
  }
  .p-lg-offset-12 {
    margin-left: 100%;
  }
  .p-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .p-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .p-lg-offset-9 {
    margin-left: 75%;
  }
  .p-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .p-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .p-lg-offset-6 {
    margin-left: 50%;
  }
  .p-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .p-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .p-lg-offset-3 {
    margin-left: 25%;
  }
  .p-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .p-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .p-lg-offset-0 {
    margin-left: 0%;
  }
  .p-lg-order-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .p-lg-order-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .p-lg-order-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .p-lg-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .p-lg-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .p-lg-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .p-lg-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .p-lg-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .p-lg-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .p-lg-order-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .p-lg-order-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .p-lg-order-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .p-lg-order-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .p-lg-order-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .p-lg-order-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
}

@media screen and (min-width: 1200px) {
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .p-xl-1 {
    width: 8.3333%;
  }
  .p-xl-2 {
    width: 16.6667%;
  }
  .p-xl-3 {
    width: 25%;
  }
  .p-xl-4 {
    width: 33.3333%;
  }
  .p-xl-5 {
    width: 41.6667%;
  }
  .p-xl-6 {
    width: 50%;
  }
  .p-xl-7 {
    width: 58.3333%;
  }
  .p-xl-8 {
    width: 66.6667%;
  }
  .p-xl-9 {
    width: 75%;
  }
  .p-xl-10 {
    width: 83.3333%;
  }
  .p-xl-11 {
    width: 91.6667%;
  }
  .p-xl-12 {
    width: 100%;
  }
  .p-xl-offset-12 {
    margin-left: 100%;
  }
  .p-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .p-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .p-xl-offset-9 {
    margin-left: 75%;
  }
  .p-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .p-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .p-xl-offset-6 {
    margin-left: 50%;
  }
  .p-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .p-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .p-xl-offset-3 {
    margin-left: 25%;
  }
  .p-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .p-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .p-xl-offset-0 {
    margin-left: 0%;
  }
  .p-xl-order-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .p-xl-order-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .p-xl-order-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .p-xl-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .p-xl-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .p-xl-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .p-xl-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .p-xl-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .p-xl-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .p-xl-order-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .p-xl-order-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .p-xl-order-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .p-xl-order-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .p-xl-order-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .p-xl-order-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
}

.font-8 {
  font-size: 8px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-27 {
  font-size: 27px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-29 {
  font-size: 29px !important;
}

.font-30 {
  font-size: 30px !important;
}

.m-0 {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.m-11 {
  margin: 11px !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.mb-11 {
  margin-bottom: 11px !important;
}

.m-12 {
  margin: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.m-13 {
  margin: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.m-14 {
  margin: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.m-15 {
  margin: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.m-17 {
  margin: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.m-18 {
  margin: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.m-19 {
  margin: 19px !important;
}

.ml-19 {
  margin-left: 19px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.m-20 {
  margin: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.m-21 {
  margin: 21px !important;
}

.ml-21 {
  margin-left: 21px !important;
}

.mr-21 {
  margin-right: 21px !important;
}

.mt-21 {
  margin-top: 21px !important;
}

.mb-21 {
  margin-bottom: 21px !important;
}

.m-22 {
  margin: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.m-23 {
  margin: 23px !important;
}

.ml-23 {
  margin-left: 23px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.mt-23 {
  margin-top: 23px !important;
}

.mb-23 {
  margin-bottom: 23px !important;
}

.m-24 {
  margin: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.m-25 {
  margin: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.m-26 {
  margin: 26px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.m-27 {
  margin: 27px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.m-28 {
  margin: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.m-29 {
  margin: 29px !important;
}

.ml-29 {
  margin-left: 29px !important;
}

.mr-29 {
  margin-right: 29px !important;
}

.mt-29 {
  margin-top: 29px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.m-30 {
  margin: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.m-31 {
  margin: 31px !important;
}

.ml-31 {
  margin-left: 31px !important;
}

.mr-31 {
  margin-right: 31px !important;
}

.mt-31 {
  margin-top: 31px !important;
}

.mb-31 {
  margin-bottom: 31px !important;
}

.m-32 {
  margin: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.m-33 {
  margin: 33px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.m-34 {
  margin: 34px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.m-35 {
  margin: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.m-36 {
  margin: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.m-37 {
  margin: 37px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.mr-37 {
  margin-right: 37px !important;
}

.mt-37 {
  margin-top: 37px !important;
}

.mb-37 {
  margin-bottom: 37px !important;
}

.m-38 {
  margin: 38px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.m-39 {
  margin: 39px !important;
}

.ml-39 {
  margin-left: 39px !important;
}

.mr-39 {
  margin-right: 39px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mb-39 {
  margin-bottom: 39px !important;
}

.m-40 {
  margin: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.m-41 {
  margin: 41px !important;
}

.ml-41 {
  margin-left: 41px !important;
}

.mr-41 {
  margin-right: 41px !important;
}

.mt-41 {
  margin-top: 41px !important;
}

.mb-41 {
  margin-bottom: 41px !important;
}

.m-42 {
  margin: 42px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.m-43 {
  margin: 43px !important;
}

.ml-43 {
  margin-left: 43px !important;
}

.mr-43 {
  margin-right: 43px !important;
}

.mt-43 {
  margin-top: 43px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.m-44 {
  margin: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.m-45 {
  margin: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.m-46 {
  margin: 46px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.m-47 {
  margin: 47px !important;
}

.ml-47 {
  margin-left: 47px !important;
}

.mr-47 {
  margin-right: 47px !important;
}

.mt-47 {
  margin-top: 47px !important;
}

.mb-47 {
  margin-bottom: 47px !important;
}

.m-48 {
  margin: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.m-49 {
  margin: 49px !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.mr-49 {
  margin-right: 49px !important;
}

.mt-49 {
  margin-top: 49px !important;
}

.mb-49 {
  margin-bottom: 49px !important;
}

.m-50 {
  margin: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.p-0 {
  padding: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.p-1 {
  padding: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.p-2 {
  padding: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.p-3 {
  padding: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.p-4 {
  padding: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.p-5 {
  padding: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.p-6 {
  padding: 6px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.p-7 {
  padding: 7px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.p-8 {
  padding: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.p-9 {
  padding: 9px !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.pr-9 {
  padding-right: 9px !important;
}

.pt-9 {
  padding-top: 9px !important;
}

.pb-9 {
  padding-bottom: 9px !important;
}

.p-10 {
  padding: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.p-11 {
  padding: 11px !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.pr-11 {
  padding-right: 11px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.p-12 {
  padding: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.p-13 {
  padding: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.p-14 {
  padding: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.p-15 {
  padding: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.p-17 {
  padding: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.p-18 {
  padding: 18px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.p-19 {
  padding: 19px !important;
}

.pl-19 {
  padding-left: 19px !important;
}

.pr-19 {
  padding-right: 19px !important;
}

.pt-19 {
  padding-top: 19px !important;
}

.pb-19 {
  padding-bottom: 19px !important;
}

.p-20 {
  padding: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.p-21 {
  padding: 21px !important;
}

.pl-21 {
  padding-left: 21px !important;
}

.pr-21 {
  padding-right: 21px !important;
}

.pt-21 {
  padding-top: 21px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.p-22 {
  padding: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.p-23 {
  padding: 23px !important;
}

.pl-23 {
  padding-left: 23px !important;
}

.pr-23 {
  padding-right: 23px !important;
}

.pt-23 {
  padding-top: 23px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.p-24 {
  padding: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.p-25 {
  padding: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.p-26 {
  padding: 26px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.p-27 {
  padding: 27px !important;
}

.pl-27 {
  padding-left: 27px !important;
}

.pr-27 {
  padding-right: 27px !important;
}

.pt-27 {
  padding-top: 27px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.p-28 {
  padding: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.p-29 {
  padding: 29px !important;
}

.pl-29 {
  padding-left: 29px !important;
}

.pr-29 {
  padding-right: 29px !important;
}

.pt-29 {
  padding-top: 29px !important;
}

.pb-29 {
  padding-bottom: 29px !important;
}

.p-30 {
  padding: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.p-31 {
  padding: 31px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.pr-31 {
  padding-right: 31px !important;
}

.pt-31 {
  padding-top: 31px !important;
}

.pb-31 {
  padding-bottom: 31px !important;
}

.p-32 {
  padding: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.p-33 {
  padding: 33px !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.pr-33 {
  padding-right: 33px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.p-34 {
  padding: 34px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.p-35 {
  padding: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.p-36 {
  padding: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.p-37 {
  padding: 37px !important;
}

.pl-37 {
  padding-left: 37px !important;
}

.pr-37 {
  padding-right: 37px !important;
}

.pt-37 {
  padding-top: 37px !important;
}

.pb-37 {
  padding-bottom: 37px !important;
}

.p-38 {
  padding: 38px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.p-39 {
  padding: 39px !important;
}

.pl-39 {
  padding-left: 39px !important;
}

.pr-39 {
  padding-right: 39px !important;
}

.pt-39 {
  padding-top: 39px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.p-40 {
  padding: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.p-41 {
  padding: 41px !important;
}

.pl-41 {
  padding-left: 41px !important;
}

.pr-41 {
  padding-right: 41px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pb-41 {
  padding-bottom: 41px !important;
}

.p-42 {
  padding: 42px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.p-43 {
  padding: 43px !important;
}

.pl-43 {
  padding-left: 43px !important;
}

.pr-43 {
  padding-right: 43px !important;
}

.pt-43 {
  padding-top: 43px !important;
}

.pb-43 {
  padding-bottom: 43px !important;
}

.p-44 {
  padding: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.p-45 {
  padding: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.p-46 {
  padding: 46px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.p-47 {
  padding: 47px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.pr-47 {
  padding-right: 47px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.pb-47 {
  padding-bottom: 47px !important;
}

.p-48 {
  padding: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.p-49 {
  padding: 49px !important;
}

.pl-49 {
  padding-left: 49px !important;
}

.pr-49 {
  padding-right: 49px !important;
}

.pt-49 {
  padding-top: 49px !important;
}

.pb-49 {
  padding-bottom: 49px !important;
}

.p-50 {
  padding: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.cf-card {
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 10px 0 #d8d8d8;
  box-shadow: 0 2px 10px 0 #d8d8d8;
  padding: 0;
  width: 100%;
  line-height: 1;
  padding: 30px;
}

.height-100 {
  height: 100%;
}

body button:focus, body button:active {
  outline: none;
}

body .ui-inputtext {
  padding: 8px 10px;
  height: 36px;
}

body .ui-button {
  background-color: #27b570;
  border: 1px solid #27b570;
  border-radius: 2px;
  font-size: 14px;
  padding: 14px 30px;
  line-height: 1;
}

body .ui-button:hover, body .ui-button:focus {
  background: #fff !important;
  color: #27b570;
}

body .btn-border {
  background: #fff;
  color: #27b570;
}

body .btn-border:hover, body .btn-border:focus {
  background-color: #27b570 !important;
  color: #fff;
}

body .ui-inputswitch .ui-inputswitch-on,
body .ui-inputswitch .ui-inputswitch-off {
  line-height: 28px;
}

body .ui-inputswitch .ui-inputswitch-handle {
  background-color: #f3f3f3;
}

body .ui-inputswitch-offlabel {
  font-size: 15px;
}

body .ui-chkbox + label {
  margin-left: 12px;
}

body .profileBtn {
  padding: 0 !important;
  background-color: transparent;
  border: 1px solid transparent;
}

body .profileBtn:hover, body .profileBtn:focus {
  background-color: transparent !important;
}

body .profileMenu {
  left: inherit !important;
  right: 0 !important;
  top: 40px !important;
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  margin-right: 10px;
}

body .input-underline {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 20px;
  margin: 0 5px;
  width: 100px;
}

body .input-underline:enabled:focus:not(.ui-state-error) {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #66afe9;
}

.img-fluid {
  width: 100%;
}

.pageWrapper {
  margin-top: 0px;
  margin-bottom: 40px;
}

.subMenu {
  margin-bottom: 20px;
  padding: 0;
}

.subMenu .subMenuItem {
  position: relative;
  padding: 0;
}

.subMenu .subMenuItem:after {
  content: '';
  position: absolute;
  height: 32px;
  width: 1px;
  right: 0;
  top: 14px;
  background: #ebebeb;
}

.subMenu .subMenuItem button {
  color: #787878;
  position: relative;
  background-color: transparent;
  border: none;
  font-weight: 500 !important;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 1;
  padding: 24px 16px;
}

.subMenu .subMenuItem button.active {
  color: #3365e0;
  content: '';
}

.subMenu .subMenuItem button.active:after {
  content: '';
  background: #3365e0;
  position: absolute;
  bottom: 0;
  left: calc(50% - 15px);
  width: 30px;
  height: 3px;
}

.borderRight-1 {
  border-right: 1px solid #ebebeb;
}

.textLabel {
  font-size: 14px;
  font-weight: 500;
  color: #282828;
}

.textPrimary {
  font-size: 14px;
  font-weight: 400;
  color: #787878;
}

.textBold {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.textPrimarySemiBold {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  color: #282828;
}

.heading.small {
  font-size: 18px;
}

.colorSuccess {
  color: #27b570;
}

.colorError {
  color: #ff3a20;
}

.ta-right {
  text-align: right;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.viewMoreBtn {
  background-color: #FFF;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-block {
  display: none;
}

.d-none {
  display: none;
}

.ui-tree {
  width: 100%;
}

.faq-wrapper .ui-treenode-label {
  white-space: normal;
  width: calc(100% - 40px);
}

.faq-wrapper .ui-treenode-content .ui-treenode-label {
  font-size: 16px;
  font-weight: 500;
}

.faq-wrapper .ui-treenode-children .ui-treenode-label {
  line-height: 1.5 !important;
  font-size: 14px;
  font-weight: 400;
}

.layout-wrapper .layout-menu li a {
  padding: 20px 8px;
}

.overview-box {
  cursor: pointer;
}

.amount-b-card {
  padding-top: 2px;
  font-weight: bold;
  font-size: 18px;
}

.dashboard .overview-box-slim {
  min-height: 55px;
}
.cardBox{
  padding: 2px;
  background-color: transparent;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.dashboard .overview-box.overview-box-5 {
  background-color: #dc3545;
  border: solid 1px rgba(220, 53, 69, 0.1);
  color: #fff;
}

.l-error{
  color: #dc3545;
}

.datail-transaction p {
  padding-bottom: 5px;
  display: inline-block;
  min-width: 140px;
  text-align: right;}
.datail-transaction span {
  font-weight: bold;
  padding-bottom: 5px;
  display: inline-block;
  color: #282828;
  min-width: 140px;
}

.with-text80{
  width: 80px;
  display: inline-block;
}

.textHorizontal-width-80 {
  min-width: 80px;
}
