.p-md-2 {
    width: 20%; }

.dashboard .overview-box.overview-box-5 {
    background-color: #8dc8ff;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}

.dashboard .overview-box.overview-box-6 {
    background-color: #FFA726;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}

.dashboard .overview-box.overview-box-7 {
    background-color: #2ea59f;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}


.dashboard .overview-box.overview-box-8 {
    background-color: #a52e58;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}

.p-datatable .outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
    text-align: right;
}

.p-datatable .lowstock {
    font-weight: 700;
    color: #FFA726;
    text-align: right;
}

.p-datatable .instock {
    font-weight: 700;
    color: #66BB6A;
    text-align: right;
}

.p-datatable .row-accessories {
    background-color: rgba(0, 0, 0, 0.15) !important;
}
